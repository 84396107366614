.post:not(:last-child) {
  margin-top: 20px;
  margin-bottom: 20px;
}

.post:not(:last-child)::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 2px;
  border-bottom: 1px solid black;
}

.post .username {
  color: rgb(86, 124, 230);
  font-size: small;
}

.post p {
  margin-bottom: 2px;
  margin-top: 2px;
}

.post h2 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.postList {
  margin-left: 20px;
}