.container {
  margin: 20px;
}

.section {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.section label {
  margin-right: 10px;
}

.section input, .section textarea {
  border: 1px solid rgba(8, 46, 128, 0.4);
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
}

.submit {
  background-color: blanchedalmond;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
}